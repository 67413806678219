<template>
  <div
    class="h-screen w-full bg-promy-gray-50 flex justify-center items-center p-20 sm:p-0"
  >
    <div
      class="flex bg-white rounded-lg shadow-md py-8 px-16 sm:px-8 sm:py-4 sm:h-screen sm:justify-center sm:items-center"
    >
      <div class="flex flex-col">
        <div class="flex items-center">
          <LogoIcon class="!w-12 !h-12" />
          <p class="font-bold text-3xl font-main ml-3 text-promy-black-200">
            Promy
          </p>
        </div>
        <span class="text-promy-blue-300 font-bold text-2xl mt-9 mb-6">
          Conditions générales d’utilisation
        </span>
        <div class="border border-promy-gray-100 p-7 rounded-xl">
          <div
            class="overflow-y-auto max-h-[50vh] md:max-h-[40vh] scrollbar-thin scrollbar-thumb-promy-gray-180 scrollbar-track-promy-gray-100 scrollbar-thumb-rounded-full scrollbar-track-rounded-full pr-8"
            v-html="cgu.data.context"
          ></div>
        </div>
        <template v-if="isLoggedIn">
          <div class="flex space-x-2 mt-6 mb-10">
            <ProCheckbox
              staticLabel
              :val="is_accepted"
              id="checkbox_cgu"
              v-model="is_accepted"
            />
            <label
              for="checkbox_cgu"
              class="text-base text-promy-blue cursor-pointer"
              >J’ai lu et j’accepte les conditions générales
              d’utilisation</label
            >
          </div>
          <pro-button
            class="px-6 py-3 border-promy-green-350 rounded-lg w-64 h-[60px] sm:w-full"
            btn="primary"
            @click="acceptCGU"
          >
            Confirmez
          </pro-button>
        </template>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
export default {
  data() {
    return {
      is_accepted: false,
    }
  },
  computed: {
    ...mapGetters({
      cgu: 'auth/cgu',
      isLoggedIn: 'auth/isLoggedIn',
    }),
  },
  methods: {
    async acceptCGU() {
      if (!this.is_accepted) {
        this.toast(
          '',
          "Veuillez accepter les Conditions générales d'utilisation!",
          'warning',
        )
        return
      }
      try {
        let response = await this.$http.post(
          `grand-publics/accept-cgu/${this.cgu.data.id}`,
        )
        if (response.status === 200) {
          this.cgu.is_validated_cgu = this.cgu.data.id
          this.cgu.validated_cgu_at = new Date()
            .toISOString()
            .slice(0, 19)
            .replace(/T/g, ' ')
        }

        this.$router.push('/')
      } catch (e) {
        this.toast('', 'Réessayez!', 'warning')
      }
    },
  },
}
</script>
